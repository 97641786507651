export type ThemesPurchaseListState = {
  searchValue: string | number;
  selectedTheme: Record<string, string>;
  selectedResultPerPage: Record<string, string>;
  purchasedFromDate: string;
  purchasedToDate: string;
  targetPage: string;
};

export enum ThemesPurchaseListSelectProperty {
  ResultPerPage = 'selectedResultPerPage',
  SelectedTheme = 'selectedTheme',
}

export enum DateRangeProperties {
  PurchasedFromDate = 'purchasedFromDate',
  PurchasedToDate = 'purchasedToDate',
}

export enum ThemesPurchaseListSearchProperty {
  SearchValue = 'searchValue',
}

export type PurchasedThemeType = {
  id: number;
  theme_id: string;
  theme_name: string;
  store_url: string;
  store_name: string;
  merchant_name: string;
  merchant_email: string;
  merchant_phone: string;
  amount_paid: number;
  purchased_at: string;
};

export type ThemesPurchaseListType = {
  list: PurchasedThemeType[];
};

export type SortingStateType = {
  publishing_at: null | boolean;
};

export enum SortingStateProperties {
  Publishing_at = 'publishing_at',
}

export type PurchasesFilterParamsType = {
  term: string | number;
  theme_id: string;
  purchased_from: string;
  purchased_to: string;
  resultPerPage: string | number;
  targetPage: string;
};

export interface PurchasesFilterParamsInterface {
  theme_id?: string;
  searchTerm?: string | number;
  purchased_from?: string;
  purchased_to?: string;
  pageSize?: string | number;
  cursor?: string;
}

export type ExportPurchasesFilterParamsType = {
  theme_id: string;
  purchased_from: string;
  purchased_to: string;
};

export interface ExportPurchasesFilterParamsInterface {
  theme_id?: string;
  purchased_from?: string;
  purchased_to?: string;
}
