import Vue from 'vue';
import { reactive, ref, onBeforeMount, computed, watch } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import {
  SortingStateType,
  DateRangeProperties,
  ThemesPurchaseListType,
  SortingStateProperties,
  ThemesPurchaseListState,
  ThemesPurchaseListSelectProperty,
  ThemesPurchaseListSearchProperty,
} from '../../types/purchase-list/themesPurchaseList.type';
import { InputKeyUpKeysEnum } from '@/types/components/input/inputTypes';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getAllPurchases, exportPurchaseList } from '../../api/purchase-list/purchaseList';
import { partnerThemesList } from '@/api/top-level-apis/themes/partnerThemesList';
import ErrorModel from '@/api/models/ErrorModel';
dayjs.extend(customParseFormat);

const useThemesPurchaseList = () => {
  const RESULT_PER_PAGE = [];
  const DEFAULT_ITEM_PER_PAGE = 10;
  const loadingThemesList = ref(false);
  const isThemesListDisabled = ref(false);
  const isExportingDataInProgress = ref(false);
  const nextPage = ref('');
  const previousPage = ref('');
  const partnerThemes: { label: string; value: string }[] = [];
  const themesList = reactive(partnerThemes);
  const loadingThemesPurchaseList = ref(false);
  const originalThemesPurchaseList = reactive<ThemesPurchaseListType>({
    list: [],
  });
  const sortingStates = reactive<SortingStateType>({
    publishing_at: null,
  });

  for (let i = DEFAULT_ITEM_PER_PAGE; i <= 30; i += DEFAULT_ITEM_PER_PAGE) {
    RESULT_PER_PAGE.push({
      label: `${i}`,
      value: `${i}`,
    });
  }

  const resultPerPage = reactive({
    options: RESULT_PER_PAGE,
  });

  const themesPurchaseListState = reactive<ThemesPurchaseListState>({
    searchValue: '',
    selectedTheme: {
      label: '',
      value: '',
    },
    purchasedFromDate: '',
    purchasedToDate: '',
    selectedResultPerPage: {
      label: `${DEFAULT_ITEM_PER_PAGE}`,
      value: `${DEFAULT_ITEM_PER_PAGE}`,
    },
    targetPage: '',
  });

  const rules = {
    searchValue: {},
    selectedTheme: {
      label: {},
      value: {},
    },
    purchasedFromDate: {},
    purchasedToDate: {},
    selectedResultPerPage: {
      label: {},
      value: {},
    },
    targetPage: {},
  };

  const v$ = useVuelidate(rules, themesPurchaseListState);

  const onInputDataChange = (keyUp: KeyboardEvent, dataProperty: ThemesPurchaseListSearchProperty) => {
    const target = keyUp.target as HTMLTextAreaElement;
    if (keyUp.key === InputKeyUpKeysEnum.Enter) {
      v$.value[dataProperty].$touch();
      themesPurchaseListState[dataProperty] = target.value;
    }
  };

  const onSelectionDataChange = (changedValue: Record<string, string>, property: ThemesPurchaseListSelectProperty) => {
    v$.value[property].$touch();
    themesPurchaseListState[property] = JSON.parse(JSON.stringify(changedValue));
  };

  const onDatepickerChange = (changedValue: Record<string, string>, dataProperty: DateRangeProperties) => {
    themesPurchaseListState[dataProperty] = changedValue.value;
  };

  const getPurchaseList = async () => {
    originalThemesPurchaseList.list = [];
    loadingThemesPurchaseList.value = true;
    const parameters = {
      term: themesPurchaseListState.searchValue,
      theme_id: themesPurchaseListState.selectedTheme.value,
      purchased_from: themesPurchaseListState.purchasedFromDate,
      purchased_to: themesPurchaseListState.purchasedToDate,
      resultPerPage: themesPurchaseListState.selectedResultPerPage.value,
      targetPage: themesPurchaseListState.targetPage,
    };
    const response = await getAllPurchases(parameters);
    if (response instanceof ErrorModel) {
      Vue.$toast.error('Data not found for purchases');
      loadingThemesPurchaseList.value = false;
      return;
    }
    originalThemesPurchaseList.list = [...response.items];
    nextPage.value = response.nextPage;
    previousPage.value = response.previousPage;
    loadingThemesPurchaseList.value = false;
  };

  const getAllThemes = async () => {
    isThemesListDisabled.value = true;
    loadingThemesList.value = true;

    const response = await partnerThemesList();
    if (response instanceof ErrorModel || !response.themes) {
      isThemesListDisabled.value = true;
      loadingThemesList.value = false;
      Vue.$toast.error('Themes not found');
      return;
    }
    const themes = response.themes;
    themes.forEach((theme: { uuid: string; name: string }) => {
      partnerThemes.push({
        label: theme.name,
        value: theme.uuid,
      });
    });

    themesPurchaseListState.selectedTheme = partnerThemes.length ? partnerThemes[0] : { label: '', value: '' };
    loadingThemesList.value = false;
    isThemesListDisabled.value = false;
  };

  const onPreviousClick = async () => {
    if (previousPage) {
      themesPurchaseListState.targetPage = previousPage.value;
    }
  };

  const onNextClick = async () => {
    if (nextPage) {
      themesPurchaseListState.targetPage = nextPage.value;
    }
  };

  const isNextPageBtnDisable = computed(() => {
    return !nextPage.value;
  });

  const isPreviousPageBtnDisable = computed(() => {
    return !previousPage.value;
  });

  const onExportAllClicked = async () => {
    isExportingDataInProgress.value = true;
    const parameters = {
      theme_id: themesPurchaseListState.selectedTheme.value,
      purchased_from: themesPurchaseListState.purchasedFromDate,
      purchased_to: themesPurchaseListState.purchasedToDate,
    };
    const response = await exportPurchaseList(parameters);
    if (response instanceof ErrorModel || !response) {
      Vue.$toast.error('Unable to Export the list');
      isExportingDataInProgress.value = false;
      return;
    }
    Vue.$toast.default('You will receive an email containing the activation list shortly.');
    isExportingDataInProgress.value = false;
  };

  onBeforeMount(async () => {
    await getAllThemes();
  });

  watch(
    themesPurchaseListState,
    async (themesPurchaseListState) => {
      const purchasedStartDate = themesPurchaseListState.purchasedFromDate;
      const purchasedEndDate = themesPurchaseListState.purchasedToDate;
      if (purchasedStartDate && purchasedEndDate) {
        if (dayjs(purchasedStartDate).isAfter(dayjs(purchasedEndDate))) {
          Vue.$toast.error('Date range is not valid');
          return;
        }
      }
      await getPurchaseList();
    },
    { deep: true },
  );

  return {
    v$,
    themesList,
    resultPerPage,
    loadingThemesList,
    DateRangeProperties,
    isThemesListDisabled,
    themesPurchaseListState,
    loadingThemesPurchaseList,
    originalThemesPurchaseList,
    ThemesPurchaseListSearchProperty,
    ThemesPurchaseListSelectProperty,
    isExportingDataInProgress,
    isPreviousPageBtnDisable,
    isNextPageBtnDisable,
    onNextClick,
    onPreviousClick,
    onInputDataChange,
    onDatepickerChange,
    onExportAllClicked,
    onSelectionDataChange,
  };
};

export default useThemesPurchaseList;
