import BaseModel from '@/api/models/BaseModel';
import { PurchasedThemeType } from '../../types/purchase-list/themesPurchaseList.type';

class AllPurchasesResponseModel extends BaseModel {
  public items: PurchasedThemeType[];
  public nextPage: string;
  public previousPage: string;

  constructor(arg: any) {
    super(arg);
    this.items = arg.items;
    this.nextPage = arg.nextPage;
    this.previousPage = arg.previousPage;
  }
}

export default AllPurchasesResponseModel;
