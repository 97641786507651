import { defineComponent } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import {
  ZidInput,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidLoader,
  ZidButton,
  ZidDatepicker,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
} from '@zidsa/ui';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import ExcelIcon from '@/assets/icons/domains/partnership-admin/ExcelIcon.vue';
import useThemesPurchaseList from '../../helpers/purchase-list/useThemesPurchaseList';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ZidDatepicker,
    SearchIcon,
    ExcelIcon,
    ZidLoader,
    ZidButton,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  setup() {
    return {
      ...useThemesPurchaseList(),
    };
  },
});
