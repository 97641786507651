import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import BaseModel from '@/api/models/BaseModel';
import dayjs from 'dayjs';
import AllPurchasesResponseModel from '../../models/purchase-list/AllPurchasesResponseModel';
import {
  PurchasesFilterParamsType,
  PurchasesFilterParamsInterface,
  ExportPurchasesFilterParamsType,
  ExportPurchasesFilterParamsInterface,
} from '../../types/purchase-list/themesPurchaseList.type';

const ENDPOINT_URL = '/market/themes/purchases';

export const getAllPurchases = (
  filters: PurchasesFilterParamsType,
): Promise<AllPurchasesResponseModel | ErrorModel> => {
  const filterParams: PurchasesFilterParamsInterface = {};

  if (filters.term) {
    filterParams.searchTerm = filters.term;
  }
  if (filters.theme_id) {
    filterParams.theme_id = filters.theme_id;
  }
  if (filters.purchased_from) {
    filterParams.purchased_from = dayjs(filters.purchased_from).format('YYYY-MM-DD');
  }
  if (filters.purchased_to) {
    filterParams.purchased_to = dayjs(filters.purchased_to).format('YYYY-MM-DD');
  }
  if (filters.resultPerPage) {
    filterParams.pageSize = filters.resultPerPage;
  }
  if (filters.targetPage) {
    filterParams.cursor = filters.targetPage;
  }

  return httpClient
    .get(`${ENDPOINT_URL}`, {
      params: filterParams,
    })
    .then((response) => new AllPurchasesResponseModel(response))
    .catch((error) => error);
};

export const exportPurchaseList = (filters: ExportPurchasesFilterParamsType): Promise<BaseModel | ErrorModel> => {
  const filterParams: ExportPurchasesFilterParamsInterface = {};

  if (filters.theme_id) {
    filterParams.theme_id = filters.theme_id;
  }
  if (filters.purchased_from) {
    filterParams.purchased_from = dayjs(filters.purchased_from).format('YYYY-MM-DD');
  }
  if (filters.purchased_to) {
    filterParams.purchased_to = dayjs(filters.purchased_to).format('YYYY-MM-DD');
  }

  return httpClient
    .get(`${ENDPOINT_URL}/export`, {
      params: filterParams,
    })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
